// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {d2v4vHt9K: {hover: true}};

const serializationHash = "framer-1Mjes"

const variantClassNames = {d2v4vHt9K: "framer-v-1sdeh7x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, m2AycVn3L: link ?? props.m2AycVn3L, RVBsG_fwt: title ?? props.RVBsG_fwt ?? "Become Member"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RVBsG_fwt, m2AycVn3L, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "d2v4vHt9K", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={m2AycVn3L}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1sdeh7x", className, classNames)} framer-1c5es0u`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"d2v4vHt9K"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(21, 21, 21))", borderBottomLeftRadius: 48, borderBottomRightRadius: 48, borderTopLeftRadius: 48, borderTopRightRadius: 48, ...style}} variants={{"d2v4vHt9K-hover": {backgroundColor: "var(--token-f6bc43b4-a35d-48d9-8f21-b0b1c50dca05, rgb(40, 40, 40))"}}} {...addPropertyOverrides({"d2v4vHt9K-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1ib2xk", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-line-height": "1em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-f31f1ce1-16c9-4281-87ba-360912934036, rgb(255, 255, 255)))"}}>Become Member</motion.p></React.Fragment>} className={"framer-7m2zfy"} fonts={["FS;Satoshi-bold"]} layoutDependency={layoutDependency} layoutId={"mP_G55PkR"} style={{"--extracted-r6o4lv": "var(--token-f31f1ce1-16c9-4281-87ba-360912934036, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={RVBsG_fwt} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1Mjes.framer-1c5es0u, .framer-1Mjes .framer-1c5es0u { display: block; }", ".framer-1Mjes.framer-1sdeh7x { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 17px 16px 17px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-1Mjes .framer-7m2zfy { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1Mjes.framer-1sdeh7x { gap: 0px; } .framer-1Mjes.framer-1sdeh7x > * { margin: 0px; margin-bottom: calc(8px / 2); margin-top: calc(8px / 2); } .framer-1Mjes.framer-1sdeh7x > :first-child { margin-top: 0px; } .framer-1Mjes.framer-1sdeh7x > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 161
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ti1giYhtB":{"layout":["auto","auto"]}}}
 * @framerVariables {"RVBsG_fwt":"title","m2AycVn3L":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervmjssoOk3: React.ComponentType<Props> = withCSS(Component, css, "framer-1Mjes") as typeof Component;
export default FramervmjssoOk3;

FramervmjssoOk3.displayName = "Button/White";

FramervmjssoOk3.defaultProps = {height: 48, width: 161};

addPropertyControls(FramervmjssoOk3, {RVBsG_fwt: {defaultValue: "Become Member", displayTextArea: false, title: "Title", type: ControlType.String}, m2AycVn3L: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramervmjssoOk3, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/LAFFD4SDUCDVQEXFPDC7C53EQ4ZELWQI/PXCT3G6LO6ICM5I3NTYENYPWJAECAWDD/GHM6WVH6MILNYOOCXHXB5GTSGNTMGXZR.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})